<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="sync" permission="2" error>
            <p>Welcher Kalender soll verbunden werden?</p>
            <select  v-model="selectedCalendarId" class="flix-form-control">
              <option v-for="calendar in calendars" :key="calendar.id" :value="calendar.ID"> {{ JSON.parse(calendar.data).title }}</option>
            </select>

            <googleCalendarIntegration :isDisabled='selectedCalendarId === ""' :selectedCalendarId="selectedCalendarId"/>
            <msCalendarIntegration :isDisabled='selectedCalendarId === ""' :selectedCalendarId="selectedCalendarId"/>
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    googleCalendarIntegration () {
      return import('@/components/calendar/integrations/googleCalendarIntegration')
    },
    msCalendarIntegration () {
      return import('@/components/calendar/integrations/msCalendarIntegration')
    }
  },
  data () {
    return {
      dates: [],
      calendars: [],
      selectedCalendarId: ''
    }
  },
  mounted () {
    this.fetchDates()
    this.getCalendars()
  },
  methods: {
    fetchDates () {
      this.$flix_post({
        url: 'external_calendar/get',
        data: {
          user: this.$store.getters.user.md5_id
        },
        callback: function (ret) {
          this.dates = ret.data[1].dates
        }.bind(this)
      })
    },
    getCalendars () {
      this.$flix_post({
        url: 'external_calendar/get_calendars',
        data: {
          user: this.$store.getters.user.md5_id
        },
        callback: function (ret) {
          this.calendars = ret.data[1].calendars
        }.bind(this)
      })
    }
  }
}
</script>

<style>
  .gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1rem 0;
    padding-left: 1rem;
  }

  .gradient-list > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
  }

  .gradient-list > li::before, .gradient-list > li {
    box-shadow: 0.25rem 0.25rem 0.6rem rgb(0 0 0 / 5%), 0 0.5rem 1.125rem rgb(75 0 0 / 5%);
  }
</style>
